import React, { useCallback, useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import type { ExternalError } from '@atlaskit/jql-editor';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { NinChangeboardingTourTarget } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import JQLBuilderAdvanced from '@atlassian/jira-jql-builder-advanced/src/async.tsx';
import JQLBuilderBasic, {
	JQLBuilderBasicOldAsync as JQLBuilderBasicOld,
} from '@atlassian/jira-jql-builder-basic/src/async.tsx';
import {
	useIrremovableFieldsAnalyticsAttributes,
	type IrremovableFields,
} from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import type { PreloadedHydrateJqlBuilderQuery } from '@atlassian/jira-jql-builder-common/src/ui/hydrate-jql-builder/index.tsx';
import { JQL_BUILDER_PERFORMANCE_MARKS } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/constants.tsx';
import { usePerformanceContext } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data } from '@atlassian/jira-relay/src/__generated__/ui_jqlBuilder_JQLBuilderUI_hydrationQueryData.graphql';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import {
	mapSearchModeToAnalyticAtribute,
	SEARCH_MODE_ADVANCED,
	SEARCH_MODE_BASIC,
	SEARCH_MODE_SWITCHER_LEFT_LOCATION,
} from '../common/constants.tsx';
import type { JQLBuilderBase, OmitJQLBuilderBase, SearchMode } from '../common/types.tsx';
import { DefaultJqlBuilderContainer } from '../common/ui/default-jql-builder-container/index.tsx';
import { useSelectedSearchMode } from '../controllers/selected-search-mode-state/index.tsx';
import { useHydrationQuery } from '../services/hydration-query/index.tsx';
import {
	AdvancedEditorWrapper,
	BasicEditorListContainer,
	BeforeJqlBuilderContainer,
	BeforeJqlBuilderListItemContainer,
	Container,
} from '../styled.tsx';
import ExtraControls from './extra-controls/index.tsx';
import { SearchModeSwitcherRenderer } from './search-mode-switcher-renderer/index.tsx';

type JQLBuilderUIImplOldProps = OmitJQLBuilderBase<
	JQLBuilderBase,
	/**
	 * JQLBuilderUIImpl wants the transformed irremovableFields map instead of the raw values that
	 * were passed in from the consumer app so this becomes replaced with `irremovableFields`
	 */
	| 'basicModeVisibleFields'
	// Excluding project key from internal props
	| 'projectKeys'
> & {
	hydrationQueryRef: PreloadedHydrateJqlBuilderQuery;
	isHydrating: boolean;
	/**
	 * User's preferred earch mode prior to running `isQueryTooComplex` which may change it to
	 * advanced mode. For analytics purposes.
	 */
	selectedSearchMode: SearchMode;
	irremovableFields: IrremovableFields;

	/**
	 * Context JQL is the jql used by Graphql queries to return results based on that context
	 * eg: will return fields / issuetypes / statuses specific to a project if that project is in the context jql
	 */
	contextJql: string;
	loadingModeRef: React.MutableRefObject<SearchMode | undefined>;
};

export const JQLBuilderUIImplOld = ({
	analyticsSource,
	query,
	onSearch: onSearchProp,
	onUpdate,
	beforeControls,
	renderExtraControls,
	isSearching,
	onLoad,
	onReady,
	onJourneyStart,
	NoOptionComponent,
	jqlMessages,
	excludedFields,
	searchModes,
	selectedSearchMode,
	hydrationQueryRef,
	isHydrating,
	components,
	hideOperatorDropdownBasicMode,
	irremovableFields,
	hideTextSearchInput,
	searchModeSwitcherLocation,
	showOrderByPicker,
	contextJql,
	loadingModeRef,
	...props
}: JQLBuilderUIImplOldProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const irremovableFieldsAnalyticsAttributes =
		useIrremovableFieldsAnalyticsAttributes(irremovableFields);

	const { searchMode, setSelectedSearchMode } = useSelectedSearchMode();

	const onPerformanceMark = usePerformanceContext();

	const onSwitchToJql = useCallback(() => {
		setSelectedSearchMode(SEARCH_MODE_ADVANCED, true);
	}, [setSelectedSearchMode]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const jqlErrors = (jqlMessages || []).filter((e) => e.type === 'error') as ExternalError[];

		if (jqlErrors?.length) {
			const errorAnalyticsEvent = createAnalyticsEvent({});

			fireErrorAnalytics({
				event: errorAnalyticsEvent,
				meta: {
					id: 'jqlBuilderJqlInvalid',
					packageName: 'jiraJqlBuilder',
					teamName: 'empanada',
				},
				attributes: {
					queryMode: searchMode,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onJqlBuilderAdvancedReady = useCallback(() => {
		onPerformanceMark({
			mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_ADVANCED_JS_END,
		});
		onReady && onReady(SEARCH_MODE_ADVANCED);
	}, [onPerformanceMark, onReady]);

	const onJqlBuilderBasicReady = useCallback(() => {
		onReady && onReady(SEARCH_MODE_BASIC);
	}, [onReady]);

	let onSearch: ((jql: string) => void) | undefined = onSearchProp;

	// Keep onSearch undefined if onSearchProp is not provided, as a defined onSearch
	// will alter the behavior of the advanced JQL editor (e.g. rendering search button)
	if (onSearchProp) {
		onSearch = (jql: string) => {
			onSearchProp(jql);
			const event = createAnalyticsEvent({
				action: 'submitted',
				actionSubject: 'search',
			});
			fireUIAnalytics(event, 'jqlBuilder', { searchMode });
		};
	}

	const { SearchModeSwitcher, EditorContainer: CustomEditorContainer } = components ?? {};

	const isSearchModeToggleEnabled = searchModes ? searchModes.length > 1 : true;

	const isSearchModeSwitcherOnLeft =
		searchModeSwitcherLocation === SEARCH_MODE_SWITCHER_LEFT_LOCATION && getWillShowNav4();

	const SearchModeSwitcherComponent = useMemo(
		() => (
			<SearchModeSwitcherRenderer
				isSearchModeToggleEnabled={isSearchModeToggleEnabled}
				SearchModeSwitcher={SearchModeSwitcher}
				isHydrating={isHydrating}
				isSearchModeSwitcherOnLeft={isSearchModeSwitcherOnLeft}
			/>
		),
		[SearchModeSwitcher, isHydrating, isSearchModeSwitcherOnLeft, isSearchModeToggleEnabled],
	);

	const Editor = useMemo(() => {
		let jqlBuilderComponent: JSX.Element;
		switch (searchMode) {
			case SEARCH_MODE_BASIC:
				jqlBuilderComponent = (
					<UFOLabel name="jql-builder-basic">
						<JQLBuilderBasicOld
							query={query}
							// consumers can hide search feature from advanced jql editor by not passing onSearch Handler
							// if no onSearch handler is passed, Basic JQL editor would fallback to onUpdate handler
							onSearch={onSearch || onUpdate || noop}
							onSwitchToJql={onSwitchToJql}
							excludedFields={excludedFields}
							onReady={onJqlBuilderBasicReady}
							onJourneyStart={onJourneyStart}
							NoOptionComponent={NoOptionComponent}
							hydrationQueryRef={hydrationQueryRef}
							isHydrating={isHydrating}
							shouldUseListMarkup
							hideOperatorDropdown={hideOperatorDropdownBasicMode}
							irremovableFields={irremovableFields}
							hideTextSearchInput={hideTextSearchInput}
							showOrderByPicker={showOrderByPicker}
							contextJql={contextJql}
							{...props}
						/>
					</UFOLabel>
				);
				break;
			default: {
				jqlBuilderComponent = (
					<UFOLabel name="jql-builder-advanced">
						<NinChangeboardingTourTarget engagementId="nin.jql-builder.advanced">
							<AdvancedEditorWrapper>
								<JQLBuilderAdvanced
									analyticsSource={analyticsSource}
									onSearch={onSearch}
									onUpdate={onUpdate}
									query={query}
									jqlMessages={jqlMessages}
									isSearching={isSearching}
									isCompact
									excludedAutocompleteFields={excludedFields}
									onReady={onJqlBuilderAdvancedReady}
									onJourneyStart={onJourneyStart}
									{...props}
								/>
							</AdvancedEditorWrapper>
						</NinChangeboardingTourTarget>
					</UFOLabel>
				);
			}
		}

		const BeforeJqlBuilderWrapper =
			searchMode === SEARCH_MODE_BASIC
				? BeforeJqlBuilderListItemContainer
				: BeforeJqlBuilderContainer;

		return (
			<>
				{beforeControls && <BeforeJqlBuilderWrapper>{beforeControls}</BeforeJqlBuilderWrapper>}
				{isSearchModeSwitcherOnLeft && (
					<BeforeJqlBuilderWrapper>{SearchModeSwitcherComponent}</BeforeJqlBuilderWrapper>
				)}
				{jqlBuilderComponent}
				{renderExtraControls && (
					<ExtraControls
						searchMode={searchMode}
						renderExtraControls={renderExtraControls}
						shouldUseListMarkup={searchMode === SEARCH_MODE_BASIC}
					/>
				)}
			</>
		);
	}, [
		searchMode,
		beforeControls,
		isSearchModeSwitcherOnLeft,
		SearchModeSwitcherComponent,
		renderExtraControls,
		query,
		onSearch,
		onUpdate,
		onSwitchToJql,
		excludedFields,
		onJqlBuilderBasicReady,
		onJourneyStart,
		NoOptionComponent,
		hydrationQueryRef,
		isHydrating,
		hideOperatorDropdownBasicMode,
		irremovableFields,
		hideTextSearchInput,
		contextJql,
		props,
		analyticsSource,
		jqlMessages,
		isSearching,
		onJqlBuilderAdvancedReady,
		showOrderByPicker,
	]);

	const EffectiveJqlBuilderContainer = CustomEditorContainer ?? DefaultJqlBuilderContainer;

	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<Container data-testid="jql-builder">
			<EffectiveJqlBuilderContainer
				showAdvancedEditor={searchMode === SEARCH_MODE_ADVANCED}
				data-testid="jql-builder.ui.effective-jql-builder-container"
			>
				{searchMode === SEARCH_MODE_BASIC ? (
					<BasicEditorListContainer>{Editor}</BasicEditorListContainer>
				) : (
					Editor
				)}
			</EffectiveJqlBuilderContainer>
			{!isSearchModeSwitcherOnLeft && SearchModeSwitcherComponent}
			<FireUiAnalytics
				actionSubject="screen"
				action="viewed"
				actionSubjectId="jqlBuilder"
				attributes={{
					selectedSearchMode,
					queryMode: mapSearchModeToAnalyticAtribute[searchMode],
					...irremovableFieldsAnalyticsAttributes,
					loadingMode: loadingModeRef.current,
				}}
			/>
		</Container>
	);
};

type JQLBuilderUIImplProps = OmitJQLBuilderBase<
	JQLBuilderBase,
	/**
	 * JQLBuilderUIImpl wants the transformed irremovableFields map instead of the raw values that
	 * were passed in from the consumer app so this becomes replaced with `irremovableFields`
	 */
	| 'basicModeVisibleFields'
	// Excluding project key from internal props
	| 'projectKeys'
> & {
	hydrationData: ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data;
	/**
	 * User's preferred earch mode prior to running `isQueryTooComplex` which may change it to
	 * advanced mode. For analytics purposes.
	 */
	selectedSearchMode: SearchMode;
	irremovableFields: IrremovableFields;

	/**
	 * Context JQL is the jql used by Graphql queries to return results based on that context
	 * eg: will return fields / issuetypes / statuses specific to a project if that project is in the context jql
	 */
	contextJql: string;
	loadingModeRef: React.MutableRefObject<SearchMode | undefined>;
};

export const JQLBuilderUIImpl = ({
	analyticsSource,
	query,
	onSearch: onSearchProp,
	onUpdate,
	beforeControls,
	renderExtraControls,
	isSearching,
	onLoad,
	onReady,
	onJourneyStart,
	NoOptionComponent,
	jqlMessages,
	excludedFields,
	searchModes,
	selectedSearchMode,
	hydrationData,
	components,
	hideOperatorDropdownBasicMode,
	irremovableFields,
	hideTextSearchInput,
	searchModeSwitcherLocation,
	showOrderByPicker,
	contextJql,
	loadingModeRef,
	...props
}: JQLBuilderUIImplProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const irremovableFieldsAnalyticsAttributes =
		useIrremovableFieldsAnalyticsAttributes(irremovableFields);

	const { searchMode, setSelectedSearchMode } = useSelectedSearchMode();
	const { hydrationQueryIsFetching } = useHydrationQuery();

	const onPerformanceMark = usePerformanceContext();

	const onSwitchToJql = useCallback(() => {
		setSelectedSearchMode(SEARCH_MODE_ADVANCED, true);
	}, [setSelectedSearchMode]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const jqlErrors = (jqlMessages || []).filter((e) => e.type === 'error') as ExternalError[];

		if (jqlErrors?.length) {
			const errorAnalyticsEvent = createAnalyticsEvent({});

			fireErrorAnalytics({
				event: errorAnalyticsEvent,
				meta: {
					id: 'jqlBuilderJqlInvalid',
					packageName: 'jiraJqlBuilder',
					teamName: 'empanada',
				},
				attributes: {
					queryMode: searchMode,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onJqlBuilderAdvancedReady = useCallback(() => {
		onPerformanceMark({
			mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_ADVANCED_JS_END,
		});
		onReady && onReady(SEARCH_MODE_ADVANCED);
	}, [onPerformanceMark, onReady]);

	const onJqlBuilderBasicReady = useCallback(() => {
		onReady && onReady(SEARCH_MODE_BASIC);
	}, [onReady]);

	let onSearch: ((jql: string) => void) | undefined = onSearchProp;

	// Keep onSearch undefined if onSearchProp is not provided, as a defined onSearch
	// will alter the behavior of the advanced JQL editor (e.g. rendering search button)
	if (onSearchProp) {
		onSearch = (jql: string) => {
			onSearchProp(jql);
			const event = createAnalyticsEvent({
				action: 'submitted',
				actionSubject: 'search',
			});
			fireUIAnalytics(event, 'jqlBuilder', { searchMode });
		};
	}

	const { SearchModeSwitcher, EditorContainer: CustomEditorContainer } = components ?? {};

	const isSearchModeToggleEnabled = searchModes ? searchModes.length > 1 : true;

	const isSearchModeSwitcherOnLeft =
		searchModeSwitcherLocation === SEARCH_MODE_SWITCHER_LEFT_LOCATION && getWillShowNav4();

	const SearchModeSwitcherComponent = useMemo(
		() => (
			<SearchModeSwitcherRenderer
				isSearchModeToggleEnabled={isSearchModeToggleEnabled}
				SearchModeSwitcher={SearchModeSwitcher}
				isHydrating={hydrationQueryIsFetching}
				isSearchModeSwitcherOnLeft={isSearchModeSwitcherOnLeft}
			/>
		),
		[
			SearchModeSwitcher,
			hydrationQueryIsFetching,
			isSearchModeSwitcherOnLeft,
			isSearchModeToggleEnabled,
		],
	);

	const Editor = useMemo(() => {
		let jqlBuilderComponent: JSX.Element;
		switch (searchMode) {
			case SEARCH_MODE_BASIC:
				jqlBuilderComponent = (
					<UFOLabel name="jql-builder-basic">
						<JQLBuilderBasic
							query={query}
							// consumers can hide search feature from advanced jql editor by not passing onSearch Handler
							// if no onSearch handler is passed, Basic JQL editor would fallback to onUpdate handler
							onSearch={onSearch || onUpdate || noop}
							onSwitchToJql={onSwitchToJql}
							excludedFields={excludedFields}
							onReady={onJqlBuilderBasicReady}
							onJourneyStart={onJourneyStart}
							NoOptionComponent={NoOptionComponent}
							hydrationData={hydrationData}
							isHydrating={hydrationQueryIsFetching}
							shouldUseListMarkup
							hideOperatorDropdown={hideOperatorDropdownBasicMode}
							irremovableFields={irremovableFields}
							hideTextSearchInput={hideTextSearchInput}
							showOrderByPicker={showOrderByPicker}
							contextJql={contextJql}
							{...props}
						/>
					</UFOLabel>
				);
				break;
			default: {
				jqlBuilderComponent = (
					<UFOLabel name="jql-builder-advanced">
						<NinChangeboardingTourTarget engagementId="nin.jql-builder.advanced">
							<AdvancedEditorWrapper>
								<JQLBuilderAdvanced
									analyticsSource={analyticsSource}
									onSearch={onSearch}
									onUpdate={onUpdate}
									query={query}
									jqlMessages={jqlMessages}
									isSearching={isSearching}
									isCompact
									excludedAutocompleteFields={excludedFields}
									onReady={onJqlBuilderAdvancedReady}
									onJourneyStart={onJourneyStart}
									{...props}
								/>
							</AdvancedEditorWrapper>
						</NinChangeboardingTourTarget>
					</UFOLabel>
				);
			}
		}

		const BeforeJqlBuilderWrapper =
			searchMode === SEARCH_MODE_BASIC
				? BeforeJqlBuilderListItemContainer
				: BeforeJqlBuilderContainer;

		return (
			<>
				{beforeControls && <BeforeJqlBuilderWrapper>{beforeControls}</BeforeJqlBuilderWrapper>}
				{isSearchModeSwitcherOnLeft && (
					<BeforeJqlBuilderWrapper>{SearchModeSwitcherComponent}</BeforeJqlBuilderWrapper>
				)}
				{jqlBuilderComponent}
				{renderExtraControls && (
					<ExtraControls
						searchMode={searchMode}
						renderExtraControls={renderExtraControls}
						shouldUseListMarkup={searchMode === SEARCH_MODE_BASIC}
					/>
				)}
			</>
		);
	}, [
		searchMode,
		beforeControls,
		isSearchModeSwitcherOnLeft,
		SearchModeSwitcherComponent,
		renderExtraControls,
		query,
		onSearch,
		onUpdate,
		onSwitchToJql,
		excludedFields,
		onJqlBuilderBasicReady,
		onJourneyStart,
		NoOptionComponent,
		hydrationData,
		hydrationQueryIsFetching,
		hideOperatorDropdownBasicMode,
		irremovableFields,
		hideTextSearchInput,
		showOrderByPicker,
		contextJql,
		props,
		analyticsSource,
		jqlMessages,
		isSearching,
		onJqlBuilderAdvancedReady,
	]);

	const EffectiveJqlBuilderContainer = CustomEditorContainer ?? DefaultJqlBuilderContainer;

	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<Container data-testid="jql-builder">
			<EffectiveJqlBuilderContainer
				showAdvancedEditor={searchMode === SEARCH_MODE_ADVANCED}
				data-testid="jql-builder.ui.effective-jql-builder-container"
			>
				{searchMode === SEARCH_MODE_BASIC ? (
					<BasicEditorListContainer>{Editor}</BasicEditorListContainer>
				) : (
					Editor
				)}
			</EffectiveJqlBuilderContainer>
			{!isSearchModeSwitcherOnLeft && SearchModeSwitcherComponent}
			<FireUiAnalytics
				actionSubject="screen"
				action="viewed"
				actionSubjectId="jqlBuilder"
				attributes={{
					selectedSearchMode,
					queryMode: mapSearchModeToAnalyticAtribute[searchMode],
					...irremovableFieldsAnalyticsAttributes,
					loadingMode: loadingModeRef.current,
				}}
			/>
		</Container>
	);
};

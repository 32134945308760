import React, { type ReactNode, type ComponentType, useCallback, useEffect, useMemo } from 'react';
import { JQLEditorReadOnly, preloadJQLEditor } from '@atlaskit/jql-editor';
import { fg } from '@atlassian/jira-feature-gating';
import { preloadJqlBuilderBasic } from '@atlassian/jira-jql-builder-basic/src/async.tsx';
import { JQLBuilderBasicFallback } from '@atlassian/jira-jql-builder-basic/src/ui/index.tsx';
import type { IrremovableFields } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { isQueryTooComplex } from '@atlassian/jira-jql-builder-basic/src/utils/is-query-too-complex/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	type SEARCH_MODE_SWITCHER_RIGHT_LOCATION,
	SEARCH_MODE_ADVANCED,
	SEARCH_MODE_BASIC,
	SEARCH_MODE_SWITCHER_LEFT_LOCATION,
} from '../../common/constants.tsx';
import type { CustomComponents, SearchMode } from '../../common/types.tsx';
import { SearchModeSwitcher as DefaultSearchModeSwitcher } from '../../common/ui/components/search-mode-switcher/index.tsx';
import { DefaultJqlBuilderContainer } from '../../common/ui/default-jql-builder-container/index.tsx';
import { SearchModeSwitcherContainer } from '../../common/ui/search-mode-switcher-container/index.tsx';
import {
	Container,
	BeforeJqlBuilderContainer,
	AdvancedEditorWrapper,
	BeforeJqlBuilderListItemContainer,
	BasicEditorListContainer,
} from '../../styled.tsx';
import ExtraControls from '../extra-controls/index.tsx';

type JQLBuilderFallbackProps = {
	selectedSearchMode: SearchMode;
	searchModes: SearchMode[];
	query: string;
	onLoad?: (searchMode: SearchMode) => void;
	isSearchModeToggleEnabled: boolean;
	renderExtraControls?: () => ComponentType<{
		searchMode: SearchMode;
	}>[];
	beforeControls?: ReactNode;
	excludedFields?: string[];
	/**
	 * Allows consumers to provide custom components to be rendered in certain parts of the JQL builder.
	 */
	components?: CustomComponents;
	irremovableFields: IrremovableFields;
	hideTextSearchInput?: boolean;
	/**
	 * Specifies the location of the search mode switcher
	 * The search mode switcher can be placed to the left or right of the JQL Builder
	 *
	 * For locations where we need a compact (eg one row) header, the search mode switcher will be placed to the left
	 */
	searchModeSwitcherLocation?:
		| typeof SEARCH_MODE_SWITCHER_LEFT_LOCATION
		| typeof SEARCH_MODE_SWITCHER_RIGHT_LOCATION;

	showOrderByPicker?: boolean;
};

export const JQLBuilderFallback = ({
	selectedSearchMode,
	searchModes,
	query,
	onLoad,
	isSearchModeToggleEnabled,
	renderExtraControls,
	beforeControls,
	excludedFields,
	components,
	irremovableFields,
	hideTextSearchInput,
	searchModeSwitcherLocation = SEARCH_MODE_SWITCHER_LEFT_LOCATION,
	showOrderByPicker,
}: JQLBuilderFallbackProps) => {
	const onJqlBuilderBasicLoad = useCallback(() => {
		onLoad && onLoad(SEARCH_MODE_BASIC);
	}, [onLoad]);

	const searchModeCandidate = useMemo(() => {
		if (selectedSearchMode === SEARCH_MODE_ADVANCED) {
			return SEARCH_MODE_ADVANCED;
		}

		return isQueryTooComplex({
			jql: query,
			excludedFields,
			shouldValidateOperators: false,
			hideTextSearchInput,
		})
			? SEARCH_MODE_ADVANCED
			: SEARCH_MODE_BASIC;
	}, [excludedFields, hideTextSearchInput, query, selectedSearchMode]);

	/**
	 * ⚠️ This code contains architectural problems that now occur since JQL Builder being used in pages
	 * other than NIN. Since the introduction of `searchModes`, situations can arise where the
	 * computed searchMode may not exist in the available options set in `searchModes[]`. For example
	 * when `searchModes === ['basic']` but the computed search mode is set to `advanced` due to
	 * a query being too complex - LDR to do: https://hello.atlassian.net/l/cp/YrdVZ0fa
	 */
	const searchMode = useMemo(() => {
		let resolvedSearchMode = searchModeCandidate;

		if (!searchModes?.length) {
			resolvedSearchMode = searchModeCandidate;
		} else {
			resolvedSearchMode = searchModes.includes(searchModeCandidate)
				? searchModeCandidate
				: searchModes[0];
		}

		// Preload package depending on the resolved search mode
		if (resolvedSearchMode === SEARCH_MODE_ADVANCED) {
			preloadJQLEditor();
		} else {
			preloadJqlBuilderBasic();
		}

		return resolvedSearchMode;
	}, [searchModes, searchModeCandidate]);

	useEffect(() => {
		searchMode === SEARCH_MODE_ADVANCED && onLoad && onLoad(SEARCH_MODE_ADVANCED);
	}, [onLoad, searchMode]);

	const jqlBuilderFallbackComponent =
		searchMode === SEARCH_MODE_ADVANCED ? (
			<AdvancedEditorWrapper>
				<JQLEditorReadOnly isCompact isSearch query={query} />
			</AdvancedEditorWrapper>
		) : (
			<JQLBuilderBasicFallback
				query={query}
				excludedFields={excludedFields}
				onLoad={onJqlBuilderBasicLoad}
				shouldUseListMarkup
				irremovableFields={irremovableFields}
				hideTextSearchInput={hideTextSearchInput}
				showOrderByPicker={showOrderByPicker}
			/>
		);

	const { SearchModeSwitcher = DefaultSearchModeSwitcher } = components ?? {};

	const BeforeJqlBuilderWrapper =
		searchMode === SEARCH_MODE_BASIC
			? BeforeJqlBuilderListItemContainer
			: BeforeJqlBuilderContainer;

	const isSearchModeSwitcherOnLeft =
		searchModeSwitcherLocation === SEARCH_MODE_SWITCHER_LEFT_LOCATION && getWillShowNav4();

	const searchModeSwitcherComponent = (
		<SearchModeSwitcherContainer
			testId="jql-builder.ui.jql-builder-fallback.toggle-container"
			isSearchModeSwitcherOnLeft={isSearchModeSwitcherOnLeft}
		>
			<SearchModeSwitcher
				searchMode={searchMode}
				isBasicModeDisabled={false}
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onUpdateSearchMode={() => {}}
			/>
		</SearchModeSwitcherContainer>
	);

	const editorContent = (
		<>
			{beforeControls && <BeforeJqlBuilderWrapper>{beforeControls}</BeforeJqlBuilderWrapper>}
			{isSearchModeToggleEnabled && isSearchModeSwitcherOnLeft && (
				<BeforeJqlBuilderWrapper>{searchModeSwitcherComponent}</BeforeJqlBuilderWrapper>
			)}
			{jqlBuilderFallbackComponent}
			{renderExtraControls && (
				<ExtraControls
					searchMode={searchMode}
					renderExtraControls={renderExtraControls}
					shouldUseListMarkup={searchMode === SEARCH_MODE_BASIC}
				/>
			)}
		</>
	);

	return (
		<Container
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="jql-builder"
			data-vc={`jql-builder-fallback${__SERVER__ ? '-ssr' : ''}`}
			{...(__SERVER__ &&
				fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
					'data-ssr-placeholder': 'jql-builder-fallback-placeholder',
				})}
			{...(!__SERVER__ &&
				fg('add_ssr_placeholder_replacements_to_nin_and_nav') && {
					'data-ssr-placeholder-replace': 'jql-builder-fallback-placeholder',
				})}
		>
			<DefaultJqlBuilderContainer showAdvancedEditor={searchMode === SEARCH_MODE_ADVANCED}>
				{searchMode === SEARCH_MODE_BASIC ? (
					<BasicEditorListContainer data-testid="jql-builder.ui.jql-builder-fallback.basic-editor-list-container">
						{editorContent}
					</BasicEditorListContainer>
				) : (
					editorContent
				)}
			</DefaultJqlBuilderContainer>
			{isSearchModeToggleEnabled && !isSearchModeSwitcherOnLeft && searchModeSwitcherComponent}
		</Container>
	);
};

import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import { jsmProjectSettingsUrl } from './common/index.tsx';

export const routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute: InvariantRoute =
	{
		name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-permissions-next-gen`,
		path: `${jsmProjectSettingsUrl}/channels/customer-permissions`,
		exact: true,
	};

import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { browseIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueEmbedRouteEntry.tsx';
import { browseIssueRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueRouteEntry.tsx';
import { legacyRedirectRouteEntry } from '@atlassian/jira-router-routes-legacy-redirect-entries/src/legacyRedirectRouteEntry.tsx';
import type { Routes } from '@atlassian/react-resource-router/src/common/types.tsx';
import { startJiraSpa } from './start-jira-spa.tsx';

setMark('JIRA_SPA_ENTRY_LOADED');

const getInitialRoutes = () => [
	browseIssueEmbedRouteEntry,
	browseIssueRouteEntry,
	legacyRedirectRouteEntry,
];

const loadLazyRoutes = async () =>
	new Promise<() => Routes>((resolve, reject) => {
		const cb = () => {
			import(
				/* webpackChunkName: "async-route-map" */ '@atlassian/jira-routes/src/ui/spa/async-routes.tsx'
			)
				.then((mod) => {
					resolve(mod.default);
				})
				.catch((err) => {
					reject(err);
				});
		};

		// requestIdleCallback is not supported in Safari, so we need a workaround - won't be ideal for Safari
		// users but better than nothing.
		if (typeof window !== 'undefined' && typeof window.requestIdleCallback !== 'undefined') {
			window.requestIdleCallback(cb);
		} else {
			// arbitrary non-zero timeout to push the start of the lazy route load further down the path, but not _too_ far
			setTimeout(cb, 500);
		}
	});

startJiraSpa(getInitialRoutes, loadLazyRoutes);

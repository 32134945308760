import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { EMBEDDED_CONFLUENCE_MODE, parseUrl } from '@atlassian/embedded-confluence';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { embeddedPanelEntrypoint } from '../../ui/embedded-panel/entrypoint.tsx';
import { setHostnameForContent } from '../../utils/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { useParentProduct } from '../use-parent-product/index.tsx';

const entryPointParams = {};
export const useEmbeddedPanelEntryPoint = () => {
	const { locale } = useIntl();
	const [state, actions] = useEmbeddedConfluenceSidePanel();
	const {
		contentType,
		confluenceData: { contentId, contentUrl, hostname, isLive, isDraft, title, mode },
		isDeferSpaceSelection,
		contentActions: { onLinkPage },
		panel: { isOpen },
		embeddedConfluenceSource,
	} = state;
	const parentProduct = useParentProduct();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedPanelEntrypoint,
		entryPointParams,
	);

	const embeddedConfluenceSourceForPanel = `${embeddedConfluenceSource}Panel`;

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	/*
		If the user is on a custom domain, we need to update the
		hostname to reflect the parentProduct's hostname. By providing the hostname
		prop we will force EP to use the parentProduct's hostname instead of the content's hostname
		when on a custom domain (see EP code here https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/platform/packages/confluence/embedded-confluence-common/src/page/parsePageProps.ts#75).
	*/
	const contentHostname = setHostnameForContent(contentUrl, hostname);

	const onContentAction = useCallback(
		(action: string, eventName: string) => {
			fireAnalyticsEvent({
				action,
				eventName,
				embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
			});

			if (isOpen && fg('jira_issue_view_expand_modeless_ep')) {
				if (eventName === 'edit-page/close') {
					actions.setConfluenceData({
						contentUrl,
						mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
					});
				}

				if (eventName === 'edit-page/load' && action === 'taskSuccess') {
					if (mode === EMBEDDED_CONFLUENCE_MODE.VIEW_MODE) {
						actions.setConfluenceData({
							contentUrl,
							mode: EMBEDDED_CONFLUENCE_MODE.EDIT_MODE,
						});
					}
				}
			}
		},
		[actions, contentUrl, fireAnalyticsEvent, embeddedConfluenceSourceForPanel, isOpen, mode],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeEmbeddedConfluencePanel',
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
		});
		entryPointActions.unload();

		actions.closeSidePanel();
	}, [actions, fireAnalyticsEvent, entryPointActions, embeddedConfluenceSourceForPanel]);
	const editAllowedFeatures = useMemo(
		() => [...(isDeferSpaceSelection ? ['publish-modal'] : []), 'template-browser'],
		[isDeferSpaceSelection],
	);
	const shouldApplyEditFeatures = editAllowedFeatures.length > 0 && isDraft;

	const shouldOpenPanel = isOpen && contentUrl;

	const runtimeProps = useMemo(
		() => ({
			locale,
			hasFooterLogo: false,
			isDraft,
			isLive,
			hostname: contentHostname,
			panelTitle: title,
			navigationPolicy: undefined,
			onClose,
			onContentAction,
			parentProduct,
			spaceKey: parseUrl(contentUrl)?.spaceKey,
			themeState,
			url: contentUrl,
			mode,
			embeddedConfluenceSource: embeddedConfluenceSourceForPanel,
			contentId,
			contentType,
			onPublish: actions.setDeferredSpaceData,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
				...(shouldApplyEditFeatures && {
					edit: editAllowedFeatures,
				}),
			},
			linkConfluencePage: onLinkPage,
		}),
		[
			actions.setDeferredSpaceData,
			contentId,
			contentType,
			contentUrl,
			editAllowedFeatures,
			embeddedConfluenceSourceForPanel,
			contentHostname,
			isDraft,
			isLive,
			locale,
			title,
			mode,
			onClose,
			onContentAction,
			onLinkPage,
			parentProduct,
			themeState,
			shouldApplyEditFeatures,
		],
	);

	return shouldOpenPanel
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="epPanelEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { ref: undefined, entryPoint: null };
};
